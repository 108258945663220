import {Component} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, NavigationError, Router} from '@angular/router';
import {StateService} from '@shared/services/state.service';
import {CoreNavigationService, ISidenavAction} from '@angular-clan/core';
import {KeycloakService} from 'keycloak-angular';
import {CoreTimezoneService, TimeZone} from '@vm-core/form/date-time/timezone.service';
import {combineLatest, from} from 'rxjs';
import {RolesService} from '@shared/services/roles.service';
import {MainSections} from '@shared/enums/main-sections';
import {Broadcaster} from '@shared/generated/api/broadcaster';
import {MatIconRegistry} from '@angular/material/icon';
import {AtvTenantSwitchDialogComponent} from '@shared/ui/tenant-switch/tenant-switch-dialog.component';
import {INavItem} from '@angular-clan/core/lib/layout/nav-item';
import {LocalStorageService} from '@shared/services/local-storage.service';
import {LocalStorageKeys} from '@shared/enums/local-storage-keys';
import browserslist from 'raw-loader!./../../browserslist';

@Component({
    selector: 'atv-root',
    templateUrl: './app.html',
    styleUrls: ['./app.component.scss'],
})
class AppComponent {
    public urlTenantId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public browserslist: any = browserslist;

    private broadcaster!: Broadcaster;
    private broadcasters: Array<Broadcaster> = [];

    constructor(
        private router: Router,
        private stateService: StateService,
        private roleService: RolesService,
        private navigationService: CoreNavigationService,
        private keycloakService: KeycloakService,
        private iconRegistry: MatIconRegistry,
        private activatedRoute: ActivatedRoute,
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.urlTenantId = LocalStorageService.getLocalStorage(LocalStorageKeys.TENANT_ID);
        from<Promise<string>>(this.keycloakService.getToken()).subscribe((token: string) => this.stateService.setToken(token));
        this.roleService.init();

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const tenantId: number = this.activatedRoute.firstChild?.snapshot.data.tenantId;
                LocalStorageService.setLocalStorage(LocalStorageKeys.TENANT_ID, tenantId);
                this.urlTenantId = tenantId;
                if (this.broadcaster && this.urlTenantId !== this.broadcaster.id) {
                    this.stateService.setBroadcasterByTenantId(this.urlTenantId);
                }
            } else if (event instanceof NavigationError) {
                void this.router.navigate([`${Number(event.url.split('/')[1])}/insertion-order`]);
            }
        });
        combineLatest([this.stateService.currentBroadcaster$, this.stateService.availableBroadcasters$])
            .subscribe(
                ([broadcaster, broadcasters]: [Broadcaster, Array<Broadcaster>]) => {
                    if (broadcasters?.length > 0) {
                        this.broadcasters = broadcasters;
                    }
                    if (broadcaster?.id) {
                        CoreTimezoneService.setTimeZone(broadcaster.timezone as TimeZone);
                        this.broadcaster = broadcaster;
                        this.setNavigations();
                        this.navigationService.updateSidenavActionLabel(`${this.broadcaster.id}: ${this.broadcaster.name}`);
                    }
                },
            );
        this.iconRegistry.setDefaultFontSetClass('vm-icons');
    }

    private setNavigations() {
        const navItems: Array<INavItem> = this.getINavItems();
        this.navigationService.setMainSections(navItems);
        this.setSideNavAction();
        if (this.broadcaster && this.urlTenantId !== this.broadcaster.id) {
            this.setRouteOnTenantChange(navItems);
        }
    }

    private getINavItems(): Array<INavItem> {
        return [
            {
                id: MainSections.InsertionOrder,
                link: `${this.broadcaster.id}/insertion-order`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_INSERTION_ORDER:SIDENAV_MENU_ENTRY_INSERTION_ORDER`,
                icon: {
                    name: 'handshake',
                },
            },
            {
                id: MainSections.Adspot,
                link: `${this.broadcaster.id}/adspot`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_ADSPOTS:SIDENAV_MENU_ENTRY_TV_ADSPOTS`,
                icon: {
                    name: 'play_circle',
                },
            },
            {
                id: MainSections.Fillerspot,
                link: `${this.broadcaster.id}/fillerspot`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_FILLERSPOTS:SIDENAV_MENU_ENTRY_TV_FILLERSPOTS`,
                icon: {
                    name: 'ic_fillerspot',
                },
            },
            {
                id: MainSections.Company,
                link: `${this.broadcaster.id}/company`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_COMPANY:SIDENAV_MENU_ENTRY_COMPANY`,
                icon: {
                    name: 'apartment',
                },
            },
            {
                id: MainSections.Industry,
                link: `${this.broadcaster.id}/industry`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_INDUSTRY:SIDENAV_MENU_ENTRY_INDUSTRY`,
                icon: {
                    name: 'factory',
                },
            },
            {
                id: MainSections.Adblock,
                link: `${this.broadcaster.id}/adblock`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_ADBLOCKS:SIDENAV_MENU_ENTRY_TV_ADBLOCKS`,
                icon: {
                    name: 'playlist_play',
                },
            },
            {
                id: MainSections.Audience,
                link: `${this.broadcaster.id}/audience`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_AUDIENCE:SIDENAV_MENU_ENTRY_AUDIENCE`,
                icon: {
                    name: 'groups',
                },
            },
            {
                id: MainSections.Channel,
                link: `${this.broadcaster.id}/channel`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_CHANNEL:SIDENAV_MENU_ENTRY_CHANNEL`,
                icon: {
                    name: 'dvr',
                },
            },
            {
                id: MainSections.Content,
                link: `${this.broadcaster.id}/content`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_CONTENT:SIDENAV_MENU_ENTRY_CONTENT`,
                icon: {
                    name: 'content_copy',
                },
            },
            {
                id: MainSections.Tags,
                link: `${this.broadcaster.id}/tags`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TAGS:SIDENAV_MENU_ENTRY_TAGS`,
                icon: {
                    name: 'tag',
                },
            },
            {
                id: MainSections.Report,
                link: `${this.broadcaster.id}/report`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_REPORT:SIDENAV_MENU_ENTRY_TV_REPORT`,
                icon: {
                    name: 'summarize',
                },
            },
            {
                id: MainSections.Schedule,
                link: `${this.broadcaster.id}/schedule`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_SCHEDULE:SIDENAV_MENU_ENTRY_TV_SCHEDULE`,
                icon: {
                    name: 'schedule',
                },
            },
            {
                id: MainSections.History,
                link: `${this.broadcaster.id}/history`,
                name: $localize`:@@SIDENAV_MENU_ENTRY_TV_HISTORY:SIDENAV_MENU_ENTRY_TV_HISTORY`,
                icon: {
                    name: 'history',
                },
            },
        ];
    }

    private setSideNavAction(): void {
        if (this.broadcasters.length <= 1) {
            this.navigationService.setSidenavAction(null);
        } else {
            const sidenavAction: ISidenavAction<AtvTenantSwitchDialogComponent> = {
                dialogOptions: {
                    component: AtvTenantSwitchDialogComponent,
                    autoFocus: 'input',
                },
                label: `${this.broadcaster.id}: ${this.broadcaster.name}`,
                icon: {
                    name: 'account_tree_rounded',
                },
            };
            this.navigationService.setSidenavAction(sidenavAction);
        }
    }

    private setRouteOnTenantChange(navItems: Array<INavItem>): void {
        const currentRoute: string = this.router.url.split('/')[2];
        const selectedNavItem: Array<INavItem> = navItems.filter((navItem: INavItem) => navItem.id === currentRoute);
        let link: string = navItems[0].link;
        if (selectedNavItem.length > 0) {
            link = selectedNavItem[0].link;
        }
        LocalStorageService.setLocalStorage(LocalStorageKeys.TENANT_ID, this.broadcaster.id);
        void this.router.navigate([link]);
    }
}

export {AppComponent};
