import {APP_INITIALIZER, enableProdMode, isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializeKeycloak} from '@core/app.init';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    LocationStrategy,
    PathLocationStrategy,
    PercentPipe,
} from '@angular/common';
import {AppComponent} from './app.component';
import {AtvPageNotFoundModule} from '@shared/ui/page-not-found/page-not-found.module';
import {MatDialogRef} from '@angular/material/dialog';
import {CoreMainToolbarModule, CoreSidenavMenuModule, CoreSidenavModule} from '@angular-clan/core';
import {CoreAgGridLinkModule} from '@vm-core/ag-grid/cell-components/link/link.module';
import {AtvLocalizationModule} from '@shared/ui/navigation/localization/localization.module';
import {AtvUserOptionsModule} from '@shared/ui/navigation/user-options/user-options.module';
import {ApplicationContentModule} from '@shared/ui/application-content/application-content.module';
import {AppRoutingModule} from './app-routing.module';
import {Configuration as BookingConfiguration} from '@shared/generated/api/booking';
import {Configuration as BroadcasterConfiguration} from '@shared/generated/api/broadcaster';
import {Configuration as ReportingConfiguration} from '@shared/generated/api/reporting';
import {Configuration as TargetingConfiguration} from '@shared/generated/api/targeting';
import {Configuration as CappingConfiguration} from '@shared/generated/api/capping';
import {AtvRequestLoadingIndicatorModule} from '@shared/ui/request-loading-indicator/request-loading-indicator.module';
import {RequestLoadingInterceptor} from '@shared/ui/request-loading-indicator/interceptor/request-loading.interceptor';
import {AtvConfirmationDialogModule} from '@shared/ui/dialogs/confirmation/atv-confirmation-dialog.module';
import {ApiResponseInterceptor} from '@domain-api/response/api-response.interceptor';
import {StateService} from '@shared/services/state.service';
import {CoreAgGridToolbarModule} from '@angular-clan/core/grid';
import {getBroadcasterApiConfiguration} from '@domain-api/broadcaster-api-configuration.service';
import {getBookingApiConfiguration} from '@domain-api/booking-api-configuration.service';
import {getReportingApiConfiguration} from '@domain-api/reporting-api-configuration.service';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HC_noDataToDisplay from 'highcharts/modules/no-data-to-display';
import {MAT_SELECT_CONFIG, MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/slide-toggle';
import {BrowserCompatibilityComponent} from '@angular-clan/core/browser-compatibility';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AtvNewVersionToolbarComponent} from '@shared/ui/new-version-toolbar/new-version-toolbar.component';

HC_more(Highcharts);

HC_noDataToDisplay(Highcharts);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        // Core
        BrowserModule,
        BrowserAnimationsModule,
        ApplicationContentModule,
        CoreAgGridToolbarModule,
        KeycloakAngularModule,
        // Navigation - Frame
        AppRoutingModule,
        CoreSidenavModule,
        CoreSidenavMenuModule,
        CoreMainToolbarModule,
        // Other
        AtvPageNotFoundModule,
        CoreAgGridLinkModule,
        MatSnackBarModule,
        AtvLocalizationModule,
        AtvUserOptionsModule,
        AtvRequestLoadingIndicatorModule,
        AtvConfirmationDialogModule,
        MatSelectModule,
        BrowserCompatibilityComponent,
        AtvNewVersionToolbarComponent,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RequestLoadingInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        DatePipe,
        DecimalPipe,
        CurrencyPipe,
        PercentPipe,
        {provide: MatDialogRef, useValue: {}},
        {provide: BookingConfiguration, useFactory: getBookingApiConfiguration, deps: [StateService]},
        {provide: TargetingConfiguration, useFactory: getBookingApiConfiguration, deps: [StateService]},
        {provide: CappingConfiguration, useFactory: getBookingApiConfiguration, deps: [StateService]},
        {provide: BroadcasterConfiguration, useFactory: getBroadcasterApiConfiguration},
        {provide: ReportingConfiguration, useFactory: getReportingApiConfiguration, deps: [StateService]},
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {overlayPanelClass: 'expandable-overlay-panel'},
        },
        {
            provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
            useValue: {hideIcon: true, color: 'primary'},
        },
    ],
    bootstrap: [AppComponent],
})

// eslint-disable-next-line import/exports-last
export class AppModule {
}

enableProdMode();

